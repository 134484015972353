import React from "react";
import ContentWrapper from "../master/ContentWrapper";
import PageTitle from "../master/PageTittle";
import SideBar from "../master/SideBar";
import {about} from "../const";
import playground from "../../assets/about/principals_strategic_intent.jpg"

const FromThePrincipalContent = () => (
    <div className="container">
        <ContentWrapper sidebar={true} narrow={true}>
            <div className={"content"}>
                <PageTitle title="Executive Principal’s message - Dr Margaret Ghosn MSHF"/>
                <p>
                    Dear St Maroun’s College Community
                </p>
                <br/>

                <p>
                    May God’s blessings be upon each and every one of you.
                </p>
                <br/>

                <p>
                    2025 is another year of school life with its many opportunities to develop each student spiritually,
                    intellectually, physically and socially. Every student, teacher and parent is invited to challenge
                    themselves and participate in the varied events that take place throughout the year from prayer
                    experiences, mentoring, sporting competitions, stage performances, fundraisers etc. When we work
                    together and challenge one another, we make life much more exciting!
                </p>
                <br/>

                <p>
                    The mission, vision and vision for learning are fundamental to our College and I have them below, to
                    remind each one of us why we are here and what our purpose is.
                </p>
                <br/>

                <p style={{fontWeight: 'bold'}}>
                    Mission
                </p>
                <p>
                    Inspired by the Maronite Sisters of the Holy Family, we accompany our students in the realisation of
                    their potential.
                </p>
                <br/>

                <p style={{fontWeight: 'bold'}}>
                    Vision
                </p>
                <p>
                    We challenge our community to grow in faith, strive for excellence and transform the future.
                </p>
                <br/>

                <p style={{fontWeight: 'bold'}}>
                    Vision for Learning
                </p>
                <p>
                    Our vision for learning is to foster holistic development through a Christ-centred education.
                    Through 21st century practices, we accompany students to grow as critical thinkers and adaptable
                    learners, navigating an ever-changing world with resilience and compassion.
                </p>
                <br/>

                <p style={{fontWeight: 'bold'}}>
                    Student Attributes
                </p>
                <p>
                    An <text style={{fontWeight: 'bold'}}>adaptable</text> learner collaborates effectively, manages their
                    learning, and works independently, turning challenges into opportunities for growth and achievement.
                </p>
                <p>
                    A <text style={{fontWeight: 'bold'}}>resilient</text> learner embraces and perseveres through challenges
                    with a positive mindset and takes
                    ownership of their learning
                </p>
                <p>
                    A <text style={{fontWeight: 'bold'}}>thinker</text> creatively engages with ideas, critically assesses
                    them, and reflects on their learning to foster deeper understanding and innovative solutions.
                </p>
                <br/>

                <p>
                    This year we have commenced construction of our Masterplan. Demolition of parts of D block have
                    commenced to make way for renovations that will transfer the old and dark rooms into light filled
                    educational spaces that are flexible, large and equipped with STEAM equipment. At the same time as
                    we renovate D block, we are looking into the next stage of the Masterplan in order to redevelop
                    other learning spaces. As our enrolment numbers continue to grow, we need to ensure we have the
                    capacity for further intake and better classroom areas.
                </p>
                <br/>

                <p>
                    At the Executive level we are honing in on our data analysis to identify areas where we can improve
                    our performance in the academic area. The quest to always improve is what we are here for.
                </p>
                <br/>

                <p>
                    As we travel through 2025 let us remember we are a team which includes the Sisters, College
                    Chaplain, Governing Board members, Executive team, Coordinators, teachers, support staff, business
                    services and admin team, maintenance personnel who all work together with parents and students to
                    ensure everyone thrives and flourishes.
                </p>
                <br/>

                <p>
                    Ultimately our success comes down to acknowledging the grace of God in our lives.
                </p>

                <p>
                    Sr Margaret Ghosn
                </p>
                <p>
                    Principal, MSHF
                </p>

            </div>
            <SideBar items={about} title="About Us"/>
        </ContentWrapper>
    </div>
);

export default FromThePrincipalContent;
